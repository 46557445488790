<template>
  <div>
    <div class="row mb-2">
      <div class="col-auto ms-auto">
        <button
          class="btn btn-outline-primary btn-sm"
          v-if="internalTypeChoice"
          @click="clear"
        >
          <i class="far fa-times me-2"></i>Clear
        </button>
      </div>
    </div>
    <div class="row" v-if="!internalTypeChoice">
      <div class="col">
        <div
          class="card border-0 bg-light shadow-none cursor-pointer"
          @click="internalTypeChoice = 'general'"
        >
          <div class="card-body text-center">
            <div class="mb-4">
              <i class="far fa-question fa-4x text-primary"></i>
            </div>
            <div>
              <h4 class="text-primary fw-bold mb-0">
                General Enquiry or Question
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div
          class="card border-0 bg-light shadow-none cursor-pointer"
          @click="internalTypeChoice = 'issue'"
        >
          <div class="card-body text-center">
            <div class="mb-4">
              <i class="far fa-exclamation fa-4x text-primary"></i>
            </div>
            <div>
              <h4 class="text-primary fw-bold mb-0">
                A problem or bug
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->

    <div class="row" v-if="internalTypeChoice == 'general'">
      <div class="col">
        <div
          class="card border-0 bg-light shadow-none cursor-pointer"
          @click="issueType = 3"
        >
          <div class="card-body text-center">
            <div class="mb-4">
              <i class="far fa-exclamation fa-4x text-primary"></i>
            </div>
            <div>
              <h4 class="text-primary fw-bold mb-0">Ask a question</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <a
          target="_blank"
          href="https://support.swandoola.com"
          class="card border-0 bg-light shadow-none cursor-pointer"
        >
          <div class="card-body text-center">
            <div class="mb-4">
              <i class="far fa-question-circle fa-4x text-primary"></i>
            </div>
            <div>
              <h4 class="text-primary fw-bold mb-0">
                View our FAQs for solutions
              </h4>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!--  -->

    <div class="row" v-if="internalTypeChoice == 'issue'">
      <div class="col">
        <div
          class="card border-0 bg-light shadow-none cursor-pointer"
          @click="issueType = 32"
        >
          <div class="card-body text-center">
            <div class="mb-4">
              <i class="far fa-question fa-4x text-primary"></i>
            </div>
            <div>
              <h4 class="text-primary fw-bold mb-0">
                Platform Question or Issue
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div
          class="card border-0 bg-light shadow-none cursor-pointer"
          @click="issueType = 28"
        >
          <div class="card-body text-center">
            <div class="mb-4">
              <i class="far fa-bug fa-4x text-primary"></i>
            </div>
            <div>
              <h4 class="text-primary fw-bold mb-0">Error or Bug</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["input"],
  data() {
    return {
      internalTypeChoice: "",
      issueType: null,
    };
  },
  watch: {
    issueType: {
      handler: function (val, old) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    clear() {
      this.internalTypeChoice = "";
      this.issueType = null;
    },
  },
};
</script>

<style>
</style>