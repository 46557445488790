<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col">
        <h3 class="text-primary fw-bold mb-0">
          Open a support request
        </h3>
      </div>
      <div class="col-auto ms-auto">
        <router-link
          to="/support-requests"
          class="btn btn-sm btn-outline-primary"
        >
          <i class="far fa-arrow-left me-2"></i>Back to tickets
        </router-link>
      </div>
    </div>

    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="ticket-type-selection" v-if="!ticket.type">
          <ticket-type-selection v-model="ticket.type"></ticket-type-selection>
        </div>
        <!--  -->
        <div class="ticket-details" v-if="ticket.type">
          <div class="row mb-4">
            <div class="col">
              <label for="">Summary</label>
              <input
                type="text"
                v-model="ticket.summary"
                placeholder="Ticket summary..."
                class="form-control"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col">
              <label for="">Ticket Description</label>
              <markdown-editor v-model="ticket.description"></markdown-editor>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col">
              <vue-dropzone
                ref="fileUploader"
                id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-sending="sendingEvent"
                @vdropzone-complete="ticketCreated"
              ></vue-dropzone>
            </div>
          </div>

          <div class="row">
            <div class="col-auto ms-auto">
              <button
                @click="complete"
                :disabled="!canSubmit"
                class="btn btn-primary"
              >
                <i class="far fa-check me-2"></i>Create ticket
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketTypeSelection from "./partials/TicketTypeSelection.vue";
import MarkdownEditor from "./partials/MarkdownEditor.vue";
import MultiFileUpload from "./partials/MultiFileUpload.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  data() {
    return {
      internalTypeChoice: "",
      ticket: {
        summary: "",
        description: "",
        type: "",
        images: [],
      },
      dropzoneOptions: {
        autoProcessQueue: false,
        url: process.env.VUE_APP_API_URL + "/support-requests",
        thumbnailWidth: 150,
        maxFilesize: 4,
        uploadMultiple: true,
        parallelUploads: 10,
        headers: { Authorization: "Bearer " + this.$store.getters['auth/token'] },
      },
    };
  },
  computed: {
    canSubmit() {
      return (
        this.ticket.summary.length > 0 && this.ticket.description.length > 0
      );
    },
  },
  methods: {
    handleFileUpload(event) {
      this.ticket.images = event.target.files;
    },
    sendingEvent(file, xhr, formData) {
      formData.append("summary", this.ticket.summary);
      formData.append("description", this.ticket.description);
      formData.append("type", this.ticket.type);
    },
    async submitForm() {
      await this.$axios.post(
        process.env.VUE_APP_API_URL + "/support-requests",
        this.ticket
      );
      this.ticketCreated();
    },
    complete() {
      if (this.ticket.images.length > 0) {
        this.$refs.fileUploader.processQueue();
      } else {
        this.submitForm();
      }
    },
    ticketCreated() {
      this.$EventBus.$emit("alert", {
        message: "Your support request has been opened",
      });
      this.$router.push("/support-requests");
    },
  },
  components: {
    TicketTypeSelection,
    MarkdownEditor,
    MultiFileUpload,
    vueDropzone: vue2Dropzone,
  },
};
</script>

<style>
</style>