<template>
  <div>
    <div class="mb-2">
      <button @click="applyParagraph" class="btn btn-light btn-sm me-2">
        <i class="fas fa-paragraph"></i>
      </button>
      <button @click="applyBold" class="btn btn-light btn-sm me-2">
        <i class="fas fa-bold"></i>
      </button>
      <button @click="applyItalic" class="btn btn-light btn-sm me-2">
        <i class="fas fa-italic"></i>
      </button>
      <button @click="applyHeading" class="btn btn-light btn-sm me-2">
        <i class="fas fa-heading"></i>
      </button>
      <button @click="applyUl" class="btn btn-light btn-sm me-2">
        <i class="fas fa-list-ul"></i>
      </button>
      <button @click="applyOl" class="btn btn-light btn-sm me-2">
        <i class="fas fa-list-ol"></i>
      </button>
      <button @click="undo" class="btn btn-light btn-sm me-2">
        <i class="fas fa-undo"></i>
      </button>
      <button @click="redo" class="btn btn-light btn-sm me-2">
        <i class="fas fa-redo"></i>
      </button>
    </div>

    <div
      @input="onInput"
      v-html="innerValue"
      contenteditable="true"
      class="
        wysiwyg-output
        outline-none
        border-2
        p-4
        rounded-lg
        border-gray-300
        focus:border-green-300
      "
    />
  </div>
</template>

<script>
import { Marked } from "@ts-stack/markdown";
import TurndownService from "turndown";

export default {
  name: "WysiwygEditor",

  props: ["value"],

  data() {
    return {
      innerValue: Marked.parse(this.value) || "<p><br></p>",
    };
  },

  mounted() {
    document.execCommand("defaultParagraphSeparator", false, "p");
  },

  methods: {
    onInput(event) {
      const turndown = new TurndownService({
        emDelimiter: "_",
        linkStyle: "inlined",
        headingStyle: "atx",
      });
      this.$emit("input", turndown.turndown(event.target.innerHTML));
    },
    applyParagraph() {
      document.execCommand("formatBlock", false, "p");
    },
    applyBold() {
      document.execCommand("bold");
    },
    applyItalic() {
      document.execCommand("italic");
    },
    applyHeading() {
      document.execCommand("formatBlock", false, "h1");
    },
    applyUl() {
      document.execCommand("insertUnorderedList");
    },
    applyOl() {
      document.execCommand("insertOrderedList");
    },
    undo() {
      document.execCommand("undo");
    },
    redo() {
      document.execCommand("redo");
    },
  },
};
</script>